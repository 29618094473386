import React from "react";
import "./home.css";

const Mobile = "./CTA.png";

const heroSecTwo = () => {
  return (
    <div className="hero-container-two">
      <div className="hero-content-two">
        <div className="text-container-two">
          {/* <span className="home-container-sec-logo">
            <svg
              viewBox="0 0 70 70"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M35 64.1666C51.1083 64.1666 64.1667 51.1082 64.1667 34.9999C64.1667 18.8916 51.1083 5.83325 35 5.83325C18.8917 5.83325 5.83333 18.8916 5.83333 34.9999C5.83333 51.1082 18.8917 64.1666 35 64.1666Z"
                fill="url(#paint0_linear_260_4672)"
              />
              <path
                d="M35 40.1041C36.1958 40.1041 37.1875 39.1124 37.1875 37.9166V23.3333C37.1875 22.1374 36.1958 21.1458 35 21.1458C33.8042 21.1458 32.8125 22.1374 32.8125 23.3333V37.9166C32.8125 39.1124 33.8042 40.1041 35 40.1041Z"
                fill="url(#paint1_linear_260_4672)"
              />
              <path
                d="M37.6833 45.5583C37.5375 45.2083 37.3333 44.8874 37.0708 44.5958C36.7792 44.3333 36.4583 44.1291 36.1083 43.9833C35.4083 43.6916 34.5917 43.6916 33.8917 43.9833C33.5417 44.1291 33.2208 44.3333 32.9292 44.5958C32.6667 44.8874 32.4625 45.2083 32.3167 45.5583C32.1708 45.9083 32.0833 46.2874 32.0833 46.6666C32.0833 47.0458 32.1708 47.4249 32.3167 47.7749C32.4625 48.1541 32.6667 48.4458 32.9292 48.7374C33.2208 48.9999 33.5417 49.2041 33.8917 49.3499C34.2417 49.4958 34.6208 49.5833 35 49.5833C35.3792 49.5833 35.7583 49.4958 36.1083 49.3499C36.4583 49.2041 36.7792 48.9999 37.0708 48.7374C37.3333 48.4458 37.5375 48.1541 37.6833 47.7749C37.8292 47.4249 37.9167 47.0458 37.9167 46.6666C37.9167 46.2874 37.8292 45.9083 37.6833 45.5583Z"
                fill="url(#paint2_linear_260_4672)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_260_4672"
                  x1="5.83333"
                  y1="5.83325"
                  x2="72.6276"
                  y2="18.1016"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F81D1E" />
                  <stop offset="1" stop-color="#AA0506" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_260_4672"
                  x1="32.8125"
                  y1="21.1458"
                  x2="37.9818"
                  y2="21.3649"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F81D1E" />
                  <stop offset="1" stop-color="#AA0506" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_260_4672"
                  x1="32.0833"
                  y1="43.7645"
                  x2="38.7617"
                  y2="44.9942"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F81D1E" />
                  <stop offset="1" stop-color="#AA0506" />
                </linearGradient>
              </defs>
            </svg>
          </span> */}
          <h2 className="home-sec-three-title">
            What is Riley’s Alert{" "}
            <span className="home-sec-two-subtitle">®</span>?
          </h2>
          <p className="home-sec-three-paragraph home-sec-two-para">
            Riley’s Alert® is your <b>Personal Safety Companion</b>, designed to
            keep you and your trusted contacts connected when it matters most.
            Whether navigating everyday life or facing an unexpected situation,
            Riley's Alert® provides a direct line to those who care about you.
            With just one tap, you can notify your designated contacts, share
            your location, and capture real-time audio or video, ensuring you
            never face uncertainty alone.{" "}
            <b>
              Riley’s Alert® empowers you to take control in distressing
              situations, ensuring that those who care most are instantly
              informed and ready to support you.
            </b>{" "}
            It also promotes <b>transparency and accountability,</b> ensuring
            critical moments are documented when it matters most.
          </p>

          <div
            className="button-container-two"
            style={{ paddingLeft: "auto", paddingRight: "auto" }}
          >
            <a
              href="#download-app-section"
              className="Button-Regs"
              style={{ marginTop: 0, fontSize: 12 }}
            >
              Download App
            </a>
          </div>
        </div>

        <div style={{ textAlign: "center" }}>
          <img className="home-sec-three-image" src={Mobile} alt="Mobile" />
        </div>
      </div>
    </div>
  );
};

export default heroSecTwo;
