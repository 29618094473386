import React from "react";

const Remember = ({ rememberMeSelected, opposite }) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="check-box"
      style={{
        borderWidth: rememberMeSelected ? 0 : 0.9,
      }}
      onClick={opposite}
    >
      {rememberMeSelected && (
        <path
          d="M12.6033 0.558594H5.56229C2.50392 0.558594 0.680664 2.38185 0.680664 5.44022V12.4728C0.680664 15.5396 2.50392 17.3628 5.56229 17.3628H12.5949C15.6532 17.3628 17.4765 15.5396 17.4765 12.4812V5.44022C17.4849 2.38185 15.6616 0.558594 12.6033 0.558594ZM13.099 7.02822L8.33499 11.7922C8.21736 11.9099 8.05772 11.9771 7.88968 11.9771C7.72164 11.9771 7.562 11.9099 7.44437 11.7922L5.06657 9.41442C4.82291 9.17076 4.82291 8.76746 5.06657 8.5238C5.31023 8.28014 5.71353 8.28014 5.95719 8.5238L7.88968 10.4563L12.2084 6.1376C12.452 5.89394 12.8553 5.89394 13.099 6.1376C13.3427 6.38126 13.3427 6.77616 13.099 7.02822Z"
          fill="url(#paint0_linear_3252_3567)"
        />
      )}
      <defs>
        <linearGradient
          id="paint0_linear_3252_3567"
          x1="0.680664"
          y1="0.558594"
          x2="19.9133"
          y2="4.08936"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F81D1E" />
          <stop offset="1" stop-color="#AA0506" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Remember;
