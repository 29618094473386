import React from "react";
import "./DownloadAppSection.css"; // Import the CSS file
import appstore from "../../assets/appstore.svg";
import playstore from "../../assets/playstore.svg";

const DownloadAppSection = () => {
  return (
    <div className="d-main-container" id="download-app-section">
      <div className="d-sub-container">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <p className="d-title">Your Personal Safety Companion On the Go.</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 30,
              marginBottom: 30,
              alignSelf: "stretch",
            }}
          >
            <a className="downloadButton" style={{ marginRight: 10 }}>
              <img
                src={appstore}
                alt="Download from appstore"
                className="appstore-img"
              />
            </a>
            {/*  */}
            <a className="downloadButton">
              <img
                src={playstore}
                alt="Downlaod from playstore"
                className="playstore-img"
              />
            </a>
          </div>
        </div>
        <img
          src="./phones.png"
          className="mbl-img"
          style={{
            objectFit: "contain",
            maxWidth: "95%",
          }}
        />
      </div>
    </div>
  );
};

export default DownloadAppSection;
