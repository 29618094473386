import React from "react";
import "./home.css";
import { useNavigate } from "react-router";

const HeroImage = "./Hero-content.jpeg";
const BaseButton = "./Button.base.png";

const HomeHero = () => {
  const navigate = useNavigate();
  return (
    <div className="hero-container" style={{ marginTop: 50 }}>
      <div className="gradient-overlay"></div>

      <div className="centered-content">
        <div className="centered-content-title">
          Your Personal Safety Companion On the Go.
        </div>

        <div className="centered-content-subtitle">
          With just one tap, instantly alert your trusted contacts, share your
          location, and record live audio/video—ensuring peace of mind when it
          matters most.
        </div>
        <h2 className="centered-content-subtitle">
          <b>
            “With Riley’s Alert® on your smartphone, you're never truly alone.”
          </b>
        </h2>

        {/* <div className="centered-content-button">
          <button
            onClick={() => navigate("/signup")}
            className="Button-Reg"
            // style={{ fontSize: "16px", fontWeight: "700" }}
          >
            Register as Business
          </button>
        </div>

        <div className="base-button">
          <img src={BaseButton} alt="" width={35} />
        </div> */}
      </div>

      <div className="image-container">
        <img src={HeroImage} alt="Hero" />
      </div>
    </div>
  );
};

export default HomeHero;
