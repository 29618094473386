import React from "react";

const CardContainer = ({ title, text, img }) => {
  return (
    <div className="home-sec-four-card">
      <img src={img} alt="textAlert" className="home-sec-four-card-img" />
      <div className="home-sec-four-card-text-container">
        <p className="home-sec-four-card-title">{title}</p>
        <p className="home-sec-four-card-text">{text}</p>
      </div>
    </div>
  );
};

export default CardContainer;
