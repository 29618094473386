import React from "react";
import Phone from "../../../assets/Phone";
import Email from "../../../assets/Email";
import Location from "../../../assets/Location";

const Cards = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        className="contactus-card-container-container"
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#f8e2e2",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="contactus-card-container">
          <div className="contactus-card">
            <Phone />
            <div>770-285-6253</div>
          </div>
          <div className="contactus-card">
            <Email />
            <div>Info@rileysalert.com</div>
          </div>
          {/* <div className="contactus-card">
          <Location />
          <div>2464 Royal Ln. Mesa, New Jersey 45463</div>
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Cards;
