import { simplePostRequest } from "../../utils/Api";
import { MyContext } from "../../components/contextProvider/MyContextProvider";
import { useGoogleLogin } from "@react-oauth/google";
import { FacebookLoginClient } from "@greatsumini/react-facebook-login";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useSignIn = () => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const Tag = "SigninForm";
  const history = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { user, setUser } = useContext(MyContext);
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });
  const [loading, setLoading] = useState(false);
  async function onLoginPress() {
    try {
      if (!validateForm()) {
        return toast.error("Please enter correct email and password");
      }
      setLoading(true);
      const body = {
        email,
        password,
      };
      const user = await simplePostRequest("business-profile/login", body);
      if (!user?.error) {
        console.log(user);
        localStorage.setItem("user", JSON.stringify(user.data));
        setUser(user.data);
      }
    } catch (error) {
      console.log(Tag, "onLoginPress", error);
      toast.error(error?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  }
  const [rememberMeSelected, setRememberMeSelected] = useState(true);
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const validateForm = () => {
    const newErrors = { ...errors };

    newErrors.email = !emailRegex.test(email);
    newErrors.password = password.length < 8;

    setErrors(newErrors);
    // Return false if any field is invalid
    return !Object.values(newErrors).includes(true);
  };
  async function onGoogleLogin(cred) {
    try {
      if (cred.email) {
        const user = await simplePostRequest("business-profile/socialLogin", {
          email: cred.email,
          name: cred.name,
          providerId: cred.sub,
        });
        console.log(user);
        if (user?.error) {
        }
        if (user.data.redirect) {
          history(user.data.redirect);
        } else {
          localStorage.setItem("user", JSON.stringify(user.data));

          setUser(user.data);
        }
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.message || "Something went wrong");
    }
  }

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const googleUser = await fetch(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`
      );
      if (googleUser) {
        const googleUserJson = await googleUser.json();
        if (googleUserJson.email) {
          await onGoogleLogin(googleUserJson);
        }
      }
    },
  });
  const appId = "919582527015946";

  useEffect(() => {}, []);
  const facebookLogin = async () => {
    await FacebookLoginClient.loadSdk("en_Us");

    FacebookLoginClient.init({ appId, version: "v21.0" });
    FacebookLoginClient.login(
      (res) => {
        console.log(res);
        console.log("calling profile");
        FacebookLoginClient.getProfile(
          (profile) => {
            console.log("here", profile);
            if (profile.email) {
              onGoogleLogin({
                name: profile.name,
                email: profile.email,
                sub: profile.id,
              });
            }
          },
          {
            fields: "name,email,picture",
          }
        );
      },
      {
        scope: "public_profile, email",
      }
    );
  };

  const inputs = [
    {
      id: 1,
      heading: "Email",
      type: "email",
      value: email,
      error: errors.email,
      onChange: (text) => {
        setEmail(text);
        setErrors({
          ...errors,
          email: false,
        });
      },
    },
    {
      id: 2,
      heading: "Password",
      type: "password",
      value: password,
      error: errors.password,
      onChange: (text) => {
        setPassword(text);
        setErrors({
          ...errors,
          password: false,
        });
      },
    },
  ];
  const remember = () => {
    setRememberMeSelected((prev) => !prev);
  };
  const policy = () => {
    setPolicyAccepted((prev) => !prev);
  };

  return {
    inputs,
    rememberMeSelected,
    remember,
    onLoginPress,
    loading,
    login,
    facebookLogin,
    policyAccepted,
    policy
  };
};
