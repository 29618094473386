import React from "react";

function ColaborationWithPartners(props) {
  const image = [
    `https://images.unsplash.com/photo-1512102438733-bfa4ed29aef7?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D`,
    `cheerfullGuy.jpg`,
    `https://images.unsplash.com/photo-1724185116572-e59c2b205396?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D`,
    `https://images.unsplash.com/photo-1589698112632-f5dffc219c68?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D`,
    `https://images.unsplash.com/photo-1713111392225-e6a33d1210dd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D`,
    `https://images.unsplash.com/photo-1532036058531-699f6db5466e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D`,
  ];
  return (
    <div className="wir-main-container">
      <div className="wir-main-item-container">
        <p className="si-main-heading">
          Collaboration’s with{" "}
          <span
            style={{
              color: "#AA0506",
            }}
          >
            Partner
          </span>
        </p>

        <p className="para">
          We believe in the power of collaboration to enhance safety and
          connectivity. Riley’s Alert® partners with organizations, businesses,
          and community leaders to expand our reach and provide even greater
          support during critical situations. Together, we can create a safer,
          more connected world.
        </p>

        <div className="collaboration-collage-container">
          <div
            style={{
              height: "100%",
              width: "33%",
              display: "flex",
              gap: 5,
              flexDirection: "column",
            }}
          >
            <img
              src={image[0]}
              style={{
                width: "100%",
                height: "70%",
                objectFit: "cover",
              }}
            />
            <img
              src={image[3]}
              style={{
                width: "100%",
                height: "30%",
                objectFit: "cover",
              }}
            />
          </div>
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: 5,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "50%",
                gap: 5,
              }}
            >
              {" "}
              <img
                src={image[1]}
                style={{
                  width: "60%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <img
                src={image[2]}
                style={{
                  width: "40%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "50%",
                gap: 5,
              }}
            >
              {" "}
              <img
                src={image[4]}
                style={{
                  width: "50%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
              <img
                src={image[5]}
                style={{
                  width: "50%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ColaborationWithPartners;
