import React from "react";
import "./home.css"; // Import the CSS file
import Faq from "react-faq-component";

const HomeSecEight = () => {
  const data = {
    rows: [
      {
        title: "What is Riley's Alert and how does it work?",
        content: `Riley’s Alert® is your personal safety companion, designed to keep you connected and protected when it matters most. With just one tap, the app instantly notifies your designated contacts via SMS, sharing your real-time GPS location and alerting them to your situation. The app also activates an audio/video recording feature, capturing and securely storing crucial evidence off-site to enhance safety, transparency, and accountability.`,
      },
      {
        title: "How does the audio/video recording feature work?",
        content: `When you activate Riley’s Alert®, the app immediately starts recording audio and video to document events in real time. These recordings are securely stored off-site, ensuring valuable evidence is preserved and accessible when needed. The feature is only activated when you press the alert button, providing a reliable safety net without continuous background recording.`,
      },
      {
        title: "How does Riley's Alert enhance my personal safety?",
        content: `Riley’s Alert® ensures instant connectivity with your trusted contacts during urgent situations. By combining real-time GPS location sharing, instant alerts, and on-demand recording, the app empowers you with a direct link to support when you need it most. Whether you’re in distress, facing uncertainty, or need to share an urgent update, Riley’s Alert® keeps you connected and in control.
`,
      },
      {
        title: "Who can use Riley's Alert?",
        content: `Riley’s Alert® is designed for anyone who values personal safety and staying connected in critical moments. Whether you’re a student, working professional, parent, senior, solo traveler, or community member, the app provides peace of mind by ensuring your loved ones are informed and ready to respond.
`,
      },
      {
        title:
          "Is my personal data, including location information and recordings, secure?",
        content: `Absolutely. Riley’s Alert® prioritizes privacy and security by using secure servers and industry-standard encryption protocols to protect your personal information, location data, and recorded content. You remain in full control of who receives your alerts, and your data is never shared without your consent.`,
      },
      {
        title: "What if I accidentally trigger an alert?",
        content: `Riley’s Alert® includes a built-in confirmation step to prevent accidental activations. If an alert is triggered by mistake, you’ll have the option to cancel before any notifications are sent. This ensures your trusted contacts are only notified when you truly need assistance.`,
      },
      {
        title: "How do I manage my emergency contacts?",
        content: `Managing your trusted contacts is simple. Navigate to the “Contacts” section in the app to add, edit, or remove emergency contacts at any time. Keeping this list updated ensures that your support network is always ready to respond when needed.`,
      },
      {
        title:
          "What should I do if I experience a technical issue with the app?",
        content: `If you encounter any technical issues or have questions about using Riley’s Alert®, our support team is here to help. Contact us at info@rileysalert.com, and we’ll ensure your experience remains seamless, reliable, and stress-free.`,
      },
    ],
  };
  const styles = {
    rowTitleColor: "black",
    rowContentColor: "black",
    rowContentTextSize: 18,
    rowTitleTextSize: 22,
  };
  const config = {
    animate: true,
    expandIcon: "+",
    collapseIcon: "_",
  };
  return (
    <div className="home-sec-five">
      <div className="sec7MainContainer">
        <div className="faq-text-container">
          <p className="faq-title">FAQ's</p>
          <p className="faq-text">
            Explore the frequently asked questions those may answer your
            queries.
          </p>
        </div>
        <Faq data={data} styles={styles} config={config} />
      </div>
    </div>
  );
};

export default HomeSecEight;
