import React, { useEffect } from "react";
import HomeSecEight from "../home/home-hero/home-sec-eight";
import RegisterAsBusinessBanner from "../../components/RegisterAsBusinessBanner/RegisterAsBusinessBanner";
import DownloadAppSection from "../../components/downloadAppSection/DownloadAppSection";
import "./privacyAndTerms.css";

function PrivacyAndTerms(props) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, [props]);
  return (
    <div className="privacy-terms-container">
      <h4 className="sub-heading-privacy">{props.subHeading}</h4>
      <h1 className="si-main-heading privacy-heading">{props.heading}</h1>
      <div className="text-container-privacy">
        {props.data.map((element) => {
          return (
            <>
              <p className="whole-privacy-terms-text">{element}</p>
            </>
          );
        })}
      </div>
      {/* <HomeSecEight /> */}
      <RegisterAsBusinessBanner />
      <DownloadAppSection />
    </div>
  );
}

export default PrivacyAndTerms;
