import React from "react";

const Phone = () => {
  return (
    <div>
      <svg
        width="34"
        height="35"
        viewBox="0 0 34 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M16.7055 20.3073L12.1264 24.8864C11.6223 24.4383 11.1321 23.9762 10.656 23.5001C9.21366 22.0437 7.91133 20.5173 6.74904 18.9209C5.60075 17.3245 4.67652 15.7281 4.00435 14.1457C3.33218 12.5493 2.99609 11.023 2.99609 9.56659C2.99609 8.61435 3.16414 7.70412 3.50022 6.86391C3.83631 6.00969 4.36844 5.22549 5.11063 4.52532C6.00685 3.64309 6.9871 3.20898 8.02336 3.20898C8.41546 3.20898 8.80756 3.29301 9.15765 3.46105C9.52174 3.62909 9.84382 3.88115 10.0959 4.24525L13.3447 8.8244C13.5968 9.17449 13.7788 9.49657 13.9048 9.80465C14.0309 10.0987 14.1009 10.3928 14.1009 10.6589C14.1009 10.9949 14.0029 11.331 13.8068 11.6531C13.6248 11.9752 13.3587 12.3113 13.0226 12.6474L11.9584 13.7536C11.8043 13.9077 11.7343 14.0897 11.7343 14.3138C11.7343 14.4258 11.7483 14.5238 11.7763 14.6359C11.8183 14.7479 11.8603 14.8319 11.8883 14.9159C12.1404 15.378 12.5745 15.9802 13.1907 16.7084C13.8208 17.4366 14.493 18.1788 15.2212 18.9209C15.7253 19.4111 16.2154 19.8872 16.7055 20.3073Z"
          fill="url(#paint0_linear_527_2699)"
        />
        <path
          d="M30.961 26.0773C30.961 26.4694 30.891 26.8755 30.751 27.2676C30.709 27.3797 30.667 27.4917 30.6109 27.6037C30.3729 28.1078 30.0648 28.584 29.6587 29.0321C28.9725 29.7883 28.2163 30.3344 27.3621 30.6845C27.3481 30.6845 27.3341 30.6985 27.3201 30.6985C26.4939 31.0346 25.5977 31.2166 24.6314 31.2166C23.2031 31.2166 21.6767 30.8805 20.0663 30.1944C18.4559 29.5082 16.8455 28.584 15.2491 27.4217C14.7029 27.0156 14.1568 26.6095 13.6387 26.1754L18.2178 21.5962C18.6099 21.8903 18.96 22.1143 19.2541 22.2684C19.3241 22.2964 19.4081 22.3384 19.5061 22.3804C19.6182 22.4224 19.7302 22.4364 19.8562 22.4364C20.0943 22.4364 20.2763 22.3524 20.4304 22.1984L21.4947 21.1481C21.8447 20.798 22.1808 20.5319 22.5029 20.3639C22.825 20.1678 23.1471 20.0698 23.4972 20.0698C23.7632 20.0698 24.0433 20.1258 24.3514 20.2519C24.6594 20.3779 24.9815 20.5599 25.3316 20.798L29.9668 24.0888C30.3309 24.3409 30.5829 24.635 30.737 24.9851C30.877 25.3351 30.961 25.6852 30.961 26.0773Z"
          fill="url(#paint1_linear_527_2699)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_527_2699"
            x1="2.99609"
            y1="3.20898"
            x2="19.0076"
            y2="5.06888"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F81D1E" />
            <stop offset="1" stop-color="#AA0506" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_527_2699"
            x1="13.6387"
            y1="20.0698"
            x2="32.598"
            y2="25.4814"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F81D1E" />
            <stop offset="1" stop-color="#AA0506" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default Phone;
