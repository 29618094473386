import React from "react";
import "./navbar.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
const RileyLogo = "/riley-logo.png";

const Navbar = () => {
  const navigate = useNavigate();
  const width = window.width;
  const location = useLocation().pathname;
  return (
    <div className="Navbar-center">
      <div className="Navbar-Main">
        <NavLink to={"/"} style={{}}>
          <img src={RileyLogo} alt="" className="Logo" />
        </NavLink>
        <div className="Links-Main">
          <div className="links">
            <span
              className={location === "/" && "color-gradient"}
              onClick={() => navigate("/")}
            >
              Home
            </span>
            <span
              className={location === "/about-us" && "color-gradient"}
              onClick={() => navigate("/about-us")}
            >
              About Us
            </span>

            {/* <span>Blogs</span> */}
            <span
              onClick={() => navigate("/contact-us")}
              className={location === "/contact-us" && "color-gradient"}
            >
              Contact Us
            </span>
            <a href={"#download-app-section"}>
              <span>Download App</span>
            </a>
          </div>

          <sv
            className="seprator"
            style={
              width > 1132
                ? {
                    marginRight: 40,
                    marginLeft: 40,
                  }
                : {
                    marginRight: 15,
                    marginLeft: 15,
                  }
            }
            width="2"
            height="26"
            viewBox="0 0 2 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.2"
              d="M1 1L0.999999 25"
              stroke="#585858"
              stroke-linecap="round"
            />
          </sv>

          <div className="Button-Main">
            <button onClick={() => navigate("/signin")} className="Login-Btn">
              Login as Business
            </button>

            <button onClick={() => navigate("/signup")} className="Button-Reg">
              Register as Business
            </button>
          </div>
        </div>
      </div>
      {/* {} */}
      <div className="Links-Main-Mobile">
        <div className="links-mobile">
          <span
            className={location === "/" && "color-gradient"}
            onClick={() => navigate("/")}
          >
            Home
          </span>
          <span
            className={location === "/about-us" && "color-gradient"}
            onClick={() => navigate("/about-us")}
          >
            About Us
          </span>

          {/* <span>Blogs</span> */}
          <span
            className={location === "/contact-us" && "color-gradient"}
            onClick={() => navigate("/contact-us")}
          >
            Contact Us
          </span>
          <a href="#download-app-section">
            <span>Download App</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
