import React from "react";
import "./CustomTextInput.css";
export default function CustomTextInput({
  heading,
  onChange,
  value,
  style,
  type,
  error,
  placeholder,
  disabled = false,
  hideBorder = false,
}) {
  return (
    <div
      className="text-input-main-container"
      style={{
        ...style,
      }}
    >
      <div
        className="text-input-heading"
        style={error ? { color: "red" } : hideBorder ? { padding: 0 } : {}}
      >
        {heading} {error && (value.length ? "must be valid" : "is required")}
      </div>
      {placeholder === "Description" ? (
        <textarea
          // rows={7}
          className="text-input-text-input"
          
          placeholder={heading}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          style={
            error
              ? { color: "red" }
              : hideBorder
              ? { borderWidth: 0, backgroundColor: "white" }
              : {}
          }
          disabled={disabled || hideBorder}
        ></textarea>
      ) : (
        <input
          className="text-input-text-input"
          placeholder={heading}
          type={type}
          value={value}
          onChange={(e) => onChange(e.target.value)}
          style={
            error
              ? { color: "red" }
              : hideBorder
              ? { borderWidth: 0, backgroundColor: "white" }
              : {}
          }
          disabled={disabled || hideBorder}
        />
      )}
    </div>
  );
}
