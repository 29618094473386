import React from "react";
import "./home.css"; // Import the CSS file
import CardContainer from "../components/CardContainer";
const HeroThree = "./hero-three.png";
const HomeSecFour = () => {
  return (
    <div className="home-sec-four">
      <div>
        <div className="home-sec-four-container">
          <div>
            <p className="home-sec-four-title">Our Features</p>
            <p className="home-sec-four-subtitle">
              Riley’s Alert® Feature Suite
            </p>
          </div>
          <p className="home-sec-four-subtitle-text">
            Riley’s Alert® offers peace of mind through key personal safety
            features.
          </p>
        </div>

        <div className="card-container">
          <CardContainer
            title={"Instant SMS Alerts"}
            text="With a single tap, send an immediate notification to your trusted contacts, keeping them informed when you need support. When every second counts, Riley's Alert® ensures your loved ones are aware of your situation in real time."
            img={"./textAlert.png"}
          />
          {/*  */}
          <CardContainer
            title="Real-Time GPS Location Sharing"
            text="Automatically share your live location with your designated contacts, enabling them to track your whereabouts and respond accordingly. Whether you're lost, in distress, or just need reassurance, your safety network stays connected to you."
            img={"./GPSLocation.png"}
          />
          {/*  */}
          <CardContainer
            title="On-Demand Audio/Video Recording"
            text="Activate the recording feature with one tap to capture crucial details in any high-risk situation. Your recordings are stored securely off-site, providing valuable documentation that can be accessed when needed. This feature ensures transparency, accountability, and a clear record of events."
            img={"./AudioVideo.png"}
          />
        </div>
      </div>
    </div>
  );
};

export default HomeSecFour;
