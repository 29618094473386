import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";

import { Spinner } from "react-activity";
import { NavLink } from "react-router-dom";
import { useSignIn } from "./useSignin";
import Remember from "./components/Remember";
import Policy from "./components/Policy";
import facebook from "../../assets/facebookLogin.svg";
import google from "../../assets/google.svg";
import policyAccept from "../../assets/policyAccepted.svg";


export default function SigninForm() {
  const {
    inputs,
    rememberMeSelected,
    onLoginPress,
    loading,
    login,
    facebookLogin,
    policyAccepted,
    remember,
    policy,
  } = useSignIn();
  return (
    <div className="si-main-container">
      <div className="si-sub">
        <div className="si-sub-left">
          <p className="si-main-heading business-sign-in-btn">
            Business Sign In
          </p>
          <p className="new-user-btn">
            New User? <NavLink to={"/signup"}>Create an account</NavLink>
          </p>

          <div className="si-sub-left-row-container">
            {inputs.map((input) => (
              <CustomTextInput
                heading={input.heading}
                type={input.type}
                value={input.value}
                error={input.error}
                onChange={input.onChange}
                key={input.id}
              />
            ))}
          </div>
          <div className="remember-me-btn">
            <Remember
              rememberMeSelected={rememberMeSelected}
              opposite={remember}
            />
            <p>Remember me</p>
          </div>
          <div className="forgot-password-container">
            <p>
              <NavLink>Forget Password?</NavLink>
            </p>
            <button
              className="Button-Reg login-button-through-email"
              onClick={() => onLoginPress()}
              disabled={loading}
            >
              {loading ? <Spinner /> : "Login"}
            </button>
          </div>
          <div className="separater-container">
            <div className="si-sepreator" />
            <p>or</p>
            <div className="si-sepreator" />
          </div>
          <div className="socialContainer">
            <button onClick={() => login()} className="sign-in-buttons">
              <img src={google} alt=""  />
              <span>Sign in with Google</span>
            </button>
            <button onClick={() => facebookLogin()} className="sign-in-buttons">
              <img src={facebook} alt=""  />
              <span>Sign in with Facebook</span>
            </button>
          </div>
          <div className="signinform-container">
          <div
                onClick={policy}
                className="su-sub-left-policy-subContainer"
              >
                {policyAccepted ? (
                  <img src={policyAccept} />
                ) : (
                  <div className="su-sub-left-policy-box"></div>
                )}
              </div>
              <p className="su-sub-left-policy-text">
                I agree to Riley’s Alert{" "}
                <NavLink className={"su-link"} to={"/privacy-policy"}>Privacy Policy</NavLink> and{" "}
                <NavLink className={"su-link"} to={'/terms-and-condition'}>Terms of Use</NavLink>
              </p>
            </div>
    
        </div>
        <img src="./signin.png" className="si-form-image" />
      </div>
    </div>
  );
}
