import React, { useContext, useEffect, useState } from "react";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import { MyContext } from "../../components/contextProvider/MyContextProvider";
import { simplePostRequest } from "../../utils/Api";
import { Spinner } from "react-activity";

function ProfileForm() {
  const { user, setUser } = useContext(MyContext);
  const [updateProfile, setUpdateProfile] = useState(false);
  const [pointOfContact, setPointOfContact] = useState(user?.pointOfContact);
  const [fullName, setFullName] = useState(user?.name);
  const [email, setEmail] = useState(user?.email);
  const [phone, setPhone] = useState(user?.phone);

  const [zipCode, setZipCode] = useState(user?.zip_code);
  const [address, setAddress] = useState(user?.address);
  const [service, setService] = useState(user?.service);
  const [subService, setSubService] = useState(user?.subService);
  const [socialSlug, setSocialSlug] = useState("");
  const [website, setWebsite] = useState(user?.web_address);
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(user?.profileImage);
  const [errors, setErrors] = useState({
    pointOfContact: false,
    fullName: false,
    email: false,
    phone: false,
    password: false,
    confirmPassword: false,
    zipCode: false,
    address: false,
    service: false,
    subService: false,
    website: false,
    policyAccepted: false,
  });
  const [galleryImages, setGalleryImages] = useState([]);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [loadingUpdateImage, setLoadingUpdateImage] = useState("");
  const settingUser = async () => {
    try {
      setLoading(true);
      const data = {
        pointOfContact: pointOfContact,
        name: fullName,
        email,
        phone,
        zip_code: zipCode,
        address,
        service,
        subService,
        socialSlug,
        web_address: website,
        profileImage,
        gallery: galleryImages,
      };
      // setUser(data);
      
      const response = await simplePostRequest(
        "business-profile/update",
        data,
        `Bearer Bearer ${user?.jwtoken}`,
        "PATCH",
      );
      console.log(response)
      localStorage.setItem("user", JSON.stringify(response.data));
      setUser({...user,...response.data})
    } catch (error) {
      console.log(error);
    } finally {
      setUpdateProfile(false);
      setLoading(false);
    }
  };

  async function getImageUri(img) {
    try {
      console.log("getImageUri run ....");
      const formdata = new FormData();
      formdata.append("file", img);
      const image = await simplePostRequest(
        "business-profile/image-uri",
        formdata,
        user?.jwtoken,
        "POST",
        true
      );
      console.log(image);
      if (image?.data?.uri) {
        return image?.data?.uri;
      }
      return;
    } catch (error) {
      console.log("getImageUri error: ");
    }
  }
  const uploadImg = () => {
    const fileInput = document.getElementById("galleryInput");
    const handleGallery = async (e) => {
      try {
        setLoadingImage(true);
        const uploaded = await getImageUri(e.target.files[0]);
        console.log(uploaded);
        if (uploaded) {
          console.log(uploaded);
          console.log("Gallery images", galleryImages);
          let tempArr = galleryImages;
          tempArr.push(uploaded);
          setGalleryImages(tempArr);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingImage(false);
        fileInput.removeEventListener("change", handleGallery);
      }
    };
    fileInput.addEventListener("change", handleGallery);
    fileInput.click();
  };
  let condition = updateProfile
    ? true
    : galleryImages.length > 0
    ? true
    : false;
  let profileCondition = updateProfile ? true : profileImage ? true : false;
  const handleChangeImage = async (img, index) => {
    console.log("handleChangeImage Run....");
    const fileInput = document.getElementById("galleryInput");
    const handleFileSelection = async (e) => {
      if (e.target.files.length > 0) {
        try {
          setLoadingUpdateImage(img);
          const uploaded = await getImageUri(e.target.files[0]);
          if (uploaded) {
            // setGalleryImages((prev) => prev.filter((i) => i !== img));
            // setGalleryImages((prev) => [...prev, uploaded]);
            setGalleryImages((prev) => {
              const updatedImages = [...prev];
              updatedImages[index] = uploaded;
              return updatedImages;
            });
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoadingUpdateImage("");
        }
      }
      fileInput.removeEventListener("change", handleFileSelection);
    };

    fileInput.addEventListener("change", handleFileSelection);

    fileInput.click();
  };

  return (
    <div className="pf-pf-container">
      <div className="pf-pf-top-container">
        <h2 className="pf-pf-heading">User Profile</h2>
        <div>
          {updateProfile ? (
            <div style={{ position: "relative", width: "35vw" }}>
              <button
                className="Button-Reg"
                style={{
                  marginRight: 10,
                  position: "absolute",
                  right: 100,
                  bottom: 0,
                }}
                onClick={settingUser}
              >
                {loading ? <Spinner size={12} /> : "Save"}
              </button>
              <button
                onClick={() => setUpdateProfile(false)}
                className="Button-Reg"
                style={{ position: "absolute", right: 0, bottom: 0 }}
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              onClick={() => setUpdateProfile(true)}
              className="Button-Reg"
            >
              Update Profile
            </button>
          )}
        </div>
      </div>
      {profileCondition && (
        <div
          onClick={() => {
            updateProfile && document.getElementById("profileInput").click();
          }}
          style={{
            border: "solid",
            borderWidth: 1,
            borderColor: "rgba(0,0,0,0.1)",
            width: "fit-content",
            padding: profileImage ? 0 : 20,
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
            borderRadius: 10,
            cursor: "pointer",
          }}
        >
          <input
            type="file"
            id="profileInput"
            style={{
              display: "none",
            }}
            multiple={false}
            onChange={async (e) => {
              try {
                setLoadingProfile(true);
                // const value = URL.createObjectURL(e.target.files[0]);
                const uploaded = await getImageUri(e.target.files[0]);
                console.log(uploaded);
                if (uploaded) {
                  console.log(uploaded);
                  // console.log("Gallery images", galleryImages);
                  // let tempArr = galleryImages;
                  // tempArr.push(uploaded);
                  // setGalleryImages(tempArr);
                  setProfileImage(uploaded);
                }
              } catch (error) {
                console.log(error);
              } finally {
                setLoadingProfile(false);
              }
            }}
          />
          {loadingProfile ? (
            <div
              style={{
                height: 130,
                width: 80,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner />
            </div>
          ) : profileImage ? (
            <img
              src={profileImage}
              style={{
                height: 130,
                width: 100,
                objectFit: "cover",
              }}
            />
          ) : (
            <>
              <div className="pf-pf-user-logo">
                <img src="/plus.svg" alt="plus icon" />
              </div>
              <p
                style={{
                  fontWeight: "600",
                }}
              >
                Profile Image
              </p>
            </>
          )}
        </div>
      )}
      <div className="su-sub-left-row-container">
        <CustomTextInput
          heading={"Point of Contact"}
          type={"text"}
          value={pointOfContact}
          onChange={(text) => {
            setPointOfContact(text);
            setErrors({
              ...errors,
              pointOfContact: false,
            });
          }}
          hideBorder={!updateProfile}
          error={errors.pointOfContact}
        />
        <CustomTextInput
          heading={"Full Name"}
          type={"text"}
          value={fullName}
          hideBorder={!updateProfile}
          onChange={(text) => {
            setFullName(text);

            setErrors({
              ...errors,
              fullName: false,
            });
          }}
          error={errors.fullName}
        />
      </div>
      <div className="su-sub-left-row-container">
        <CustomTextInput
          heading={"Email"}
          type={"email"}
          disabled={socialSlug && email}
          value={email}
          hideBorder={!updateProfile}
          onChange={(text) => {
            setEmail(text);
            setErrors({
              ...errors,
              email: false,
            });
          }}
          error={errors.email}
        />
        <CustomTextInput
          heading={"Phone"}
          type={"tel"}
          value={phone}
          hideBorder={!updateProfile}
          onChange={(text) => {
            setPhone(text);
            setErrors({
              ...errors,
              phone: false,
            });
          }}
          error={errors.phone}
        />
      </div>

      <div className="su-sub-left-row-container">
        <CustomTextInput
          heading={"Zip Code"}
          type={"number"}
          style={{ width: "30%" }}
          value={zipCode}
          hideBorder={!updateProfile}
          onChange={(text) => {
            setZipCode(text);

            setErrors({
              ...errors,
              zipCode: false,
            });
          }}
          error={errors.zipCode}
        />
        <CustomTextInput
          heading={"Address"}
          type={"text"}
          value={address}
          hideBorder={!updateProfile}
          onChange={(text) => {
            setAddress(text);

            setErrors({
              ...errors,
              address: false,
            });
          }}
          error={errors.address}
        />
      </div>
      <div className="su-sub-left-row-container">
        <CustomTextInput
          heading={"Service"}
          type={"text"}
          value={service}
          hideBorder={!updateProfile}
          onChange={(text) => {
            setService(text);

            setErrors({
              ...errors,
              service: false,
            });
          }}
          error={errors.service}
        />
        <CustomTextInput
          heading={"Sub-Service"}
          type={"text"}
          value={subService}
          hideBorder={!updateProfile}
          onChange={(text) => {
            setSubService(text);
            setErrors({
              ...errors,
              subService: false,
            });
          }}
          error={errors.subService}
        />
      </div>
      <div className="su-sub-left-row-container">
        <CustomTextInput
          heading={"Website Address"}
          type={"url"}
          value={website}
          hideBorder={!updateProfile}
          onChange={(text) => {
            setWebsite(text);
            setErrors({
              ...errors,
              website: false,
            });
          }}
        />
      </div>
      {condition && (
        <>
          <h2>Gallery Images</h2>
          <div
            style={{
              width: "100%",
              overflowX: galleryImages.length>2?"scroll":'hidden',
              display: galleryImages.length > 2 ? "block" : "flex",
              justifyContent: "center",
            }}
          >
            <div
              className="su-sub-left-row-container"
              style={{  width: "max-content" }}
            >
              {[
                ...galleryImages,
                {
                  type: "button",
                },
              ].map((element, index) => (
                <>
                  <div
                    style={{
                      display:
                        !updateProfile && element.type == "button"
                          ? "none"
                          : "flex",
                      border: "solid",
                      borderWidth: 1,
                      borderColor: "rgba(0,0,0,0.1)",
                      width: "fit-content",
                      padding: element?.type != "button" ? 0 : 20,
                      overflow: "hidden",
                      // display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: 10,
                      borderRadius: 10,
                      cursor: "pointer",
                      // width: '100%'
                    }}
                  >
                    <input
                      type="file"
                      id="galleryInput"
                      style={{
                        display: "none",
                      }}
                      multiple={false}
                      // onChange={handleGallery}
                    />
                    {element?.type != "button" ? (
                      loadingUpdateImage === element ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 150,
                            width: 80,
                          }}
                        >
                          <Spinner />
                        </div>
                      ) : (
                        <div style={{ overflow: "auto" }}>
                          <img
                            src={element}
                            onClick={() => handleChangeImage(element, index)}
                            style={{
                              height: 150,
                              // width: 100,
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      )
                    ) : loadingImage ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: 150,
                            width: 80,
                          }}
                        >
                          <Spinner />
                        </div>
                      </>
                    ) : (
                      <div
                        className="pf-pf-user-logo"
                        // style={{height:170}}
                        onClick={() => {
                          updateProfile && uploadImg();
                        }}
                      >
                        <img src="/plus.svg" alt="plus icon" />
                      </div>
                    )}
                  </div>
                </>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ProfileForm;
