import React from "react";
import "./home.css"; // Import the CSS file
const HeroThree = "./hero-three.png";

const HomeSecSix = () => {
  const points = [
    "Medical Emergencies",
    "Missing Persons",
    "Natural Disasters",
    "Outdoor Adventures",
    "Personal Safety Concerns",
    "Public Unrest",
    "Roadside Assistance",
    "School Incidents",
    "Traffic Stops",
    "Workplace Energencies",
    "Time-Sensitive Notifications",
  ];
  return (
    <div className="home-sec-five">
      <div
        style={{
          width: "95%",
        }}
      >
        <div className="home-sec-four-container">
          <div>
            <p
              className="home-sec-four-subtitle home-sec-five-title"
              style={{
                color: "black",
              }}
            >
              When You Need{" "}
              <span
                style={{
                  color: "#F81D1E",
                }}
              >
                Riley’s Alert®
              </span>
            </p>
            <p
              className="home-sec-four-title"
              style={{
                color: "black",
              }}
            >
              Riley’s Alert® keeps you connected to your support network in a
              variety of situations, ensuring help is just a tap away.
            </p>
          </div>
        </div>

        <div className="sec-five-point-container">
          {points.map((element) => {
            return <div className="points">{element}</div>;
          })}
        </div>
        <p
          className="home-sec-four-title"
          style={{
            color: "black",
          }}
        >
          With Riley’s Alert® on your smartphone, you're never truly alone. Your
          Personal Safety Companion ensures that help is always within reach.
          Download Riley’s Alert® today and experience the comfort of staying
          connected and protected, wherever you go.
        </p>
      </div>
    </div>
  );
};

export default HomeSecSix;
