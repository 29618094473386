import { NavLink } from "react-router-dom";
import "./Footer.css";
import linkedin from "../../assets/blacklinkedin.svg";
import insta from "../../assets/blackinsta.svg";
import facebook from "../../assets/blackfacebook.svg";
import phone from "../../assets/blackphone.svg";
import email from "../../assets/blackemail.svg";
import youtube from "../../assets/youtube.svg";
import location from "../../assets/blacklocation.svg";
export default function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-sub-container">
        <div className="footer-top">
          <div className="footer-top-one">
            <p className="footer-top-one-heading">
              RILEY’s <span>ALERT</span>
            </p>
            <p className="footer-top-one-sub-heading">
              Your personal Safety Companion
            </p>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <a
                className="social-button"
                href="https://www.youtube.com/@RileysAlertofficial"
                target="_blank"
              >
                <img src={youtube} alt="" />
              </a>
              <a
                className="social-button margin-horizontol"
                href="https://www.instagram.com/rileysalert/"
                target="_blank"
              >
                <img src={insta} alt="" />
              </a>
              <a
                className="social-button"
                href="https://www.facebook.com/profile.php?id=61557742146480"
              >
                <img src={facebook} alt="" />
              </a>
            </div>
          </div>
          <div className="footer-top-two">
            <a
              className="footer-top-two-row"
              target="_blank"
              href="tel:7702856253"
            >
              <img src={phone} alt="" />
              <p className="footer-top-two-row-text">770-285-6253</p>
            </a>
            {/*  */}
            <a
              className="footer-top-two-row"
              target="_blank"
              href="mailto:Info@rileysalert.com"
            >
              <img src={email} alt="" />

              <p className="footer-top-two-row-text">Info@rileysalert.com</p>
            </a>
            {/* <a className="footer-top-two-row">
             <img src={location}/>

              <p className="footer-top-two-row-text">
                2464 Royal Ln. Mesa, New Jersey 45463
              </p>
            </a> */}
          </div>
        </div>
        <div className="footer-bottom">
          <p className="footer-bottom-text">
            © 2024 Copyright by Riley’s Alert LLC.
          </p>
          <div className="footer-bottom-links-container">
            <NavLink
              to={"/terms-and-conditions"}
              className="footer-bottom-text"
            >
              Terms & Conditions
            </NavLink>
            <NavLink
              className="footer-bottom-text margin-left"
              to={"/privacy-policy"}
            >
              Privacy Policy
            </NavLink>
            <NavLink
              className="footer-bottom-text margin-left"
              to={"/contact-us"}
            >
              Contact us
            </NavLink>
            <NavLink
              to={"/a2p-sms-service"}
              className="footer-bottom-text margin-left"
            >
              A2P Sms Service
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
