import React, { useState } from "react";
import CustomTextInput from "../../../components/CustomTextInput/CustomTextInput";
import { Spinner } from "react-activity";

const Form = () => {
  const width = window.screen.width;
  const [loading, setLoading] = useState(false);
  const [pointOfContact, setPointOfContact] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  return (
    <div
      style={{ display: "flex", justifyContent: "center", width: "100%" }}
      className="position-form"
    >
      <div className="contact-form-container">
        <div className="form-row">
          <CustomTextInput
            heading="Point of Contact"
            type="email"
            placeholder="Carlo Mercado"
            required
            maxLength={50}
            ariaLabel="Email input field"
            icon={<i className="fa fa-envelope" />}
            autoFocus
            customClassName="custom-email-input"
            value={pointOfContact}
            onChange={(text) => setPointOfContact(text)}
          />

          <CustomTextInput
            heading="Email"
            type="email"
            placeholder="Enter your email"
            required
            maxLength={50}
            ariaLabel="Email input field"
            icon={<i className="fa fa-envelope" />}
            autoFocus
            customClassName="custom-email-input"
            value={email}
            onChange={(text) => setEmail(text)}
          />
        </div>

        <div>
          <CustomTextInput
            heading="Subject *"
            type="email"
            placeholder="Enter your email"
            required
            maxLength={50}
            ariaLabel="Email input field"
            icon={<i className="fa fa-envelope" />}
            autoFocus
            customClassName="custom-email-input"
            value={subject}
            onChange={(text) => setSubject(text)}
          />
        </div>

        <div>
          <CustomTextInput
            style={{ height: width < 786 ? "147px" : "175px" }}
            heading="Message / Description *"
            type="email"
            placeholder="Description"
            required
            maxLength={50}
            ariaLabel="Email input field"
            icon={<i className="fa fa-envelope" />}
            autoFocus
            customClassName="custom-email-input"
            value={message}
            onChange={(text) => setMessage(text)}
          />
          <button
            className="btn-send"
            style={{ background: loading && "rgb(120, 53, 53)" }}
            onClick={() => setLoading((prev) => !prev)}
            disabled={loading}
          >
            {loading ? <Spinner /> : "Send Message"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Form;
