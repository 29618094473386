import React from "react";
import "./home.css"; // Import the CSS file
const HeroThree = "./hero-three.png";

const HomeSecSeven = () => {
  return (
    <div className="home-sec-five">
      <div className="sec7MainContainer s7maincontainer">
        <div className="whatWeOfferContainer">
          <div
            style={{
              alignSelf: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1>What we offer?</h1>
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.2222 5.92593C22.2222 2.65313 19.5691 0 16.2963 0H5.92593C2.65313 0 0 2.65313 0 5.92593V16.2963C0 19.5691 2.65313 22.2222 5.92593 22.2222H16.2963C19.5691 22.2222 22.2222 19.5691 22.2222 16.2963V5.92593Z"
                fill="url(#paint0_linear_258_1596)"
              />
              <path
                d="M28.8889 5.92593C28.8889 2.65313 31.542 0 34.8148 0H45.1852C48.458 0 51.1111 2.65313 51.1111 5.92593V15.5556C51.1111 18.8284 53.7642 21.4815 57.037 21.4815H74.0741C77.3469 21.4815 80 24.1346 80 27.4074V45.1852C80 48.458 77.3469 51.1111 74.0741 51.1111H57.037C53.7642 51.1111 51.1111 53.7642 51.1111 57.037V74.0741C51.1111 77.3469 48.458 80 45.1852 80H27.4074C24.1346 80 21.4815 77.3469 21.4815 74.0741V56.2963C21.4815 53.0235 24.1346 50.3704 27.4074 50.3704H44.4444C47.7172 50.3704 50.3704 47.7172 50.3704 44.4444V28.1481C50.3704 24.8754 47.7172 22.2222 44.4444 22.2222H34.8148C31.542 22.2222 28.8889 19.5691 28.8889 16.2963V5.92593Z"
                fill="url(#paint1_linear_258_1596)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_258_1596"
                  x1="0"
                  y1="0"
                  x2="91.6035"
                  y2="16.8251"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F81D1E" />
                  <stop offset="1" stop-color="#AA0506" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_258_1596"
                  x1="0"
                  y1="0"
                  x2="91.6035"
                  y2="16.8251"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F81D1E" />
                  <stop offset="1" stop-color="#AA0506" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <p>
            Riley’s Alert® provides a <b>trusted business directory</b> where
            customers can find the services they need while businesses gain
            visibility and credibility. By creating a profile, businesses can
            showcase their offerings, enhance their reputation, and connect with
            customers looking for reliable services. Users can{" "}
            <b>
              locate nearby businesses, leave ratings, and share experiences,
            </b>{" "}
            fostering a community built on trust and safety. Join Riley’s Alert®
            today and become part of a network committed to{" "}
            <b>safety, support, and reliability.</b>
          </p>
        </div>
        <img
          src={"./sec7.png"}
          alt="textAlert"
          style={{
            borderRadius: 10,
          }}
        />
      </div>
    </div>
  );
};

export default HomeSecSeven;
