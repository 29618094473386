import React from "react";
import img from "../../../assets/alert.svg";

function WhatIsRileys(props) {
  return (
    <div className="wir-main-container">
      <div className="wir-main-item-container">
        <img src={img} className="wir-main-icon" />
        <p className="si-main-heading whatisrileys">What is Riley’s Alert®?</p>

        <p className="text-para">
          <span
            style={{
              color: "#F81D1E",
              fontWeight: "bold",
            }}
          >
            Mission Statement:
          </span>{" "}
          At Riley’s Alert®, our mission is to provide families and individuals
          with peace of mind and an enhanced sense of security during life's
          most critical moments. We understand how quickly circumstances can
          change and how vital it is for loved ones to stay informed. Our
          innovative app ensures that trusted contacts receive instant alerts,
          real-time location updates, and recorded evidence when emergencies
          arise. By fostering immediate awareness and connection, Riley’s Alert®
          empowers people to act swiftly, stay informed, and never feel alone in
          times of uncertainty.
        </p>
        <img src="./whatisrileys.png" className="wir-banner" />
        <p className="text-para">
          <span
            style={{
              color: "#F81D1E",
              fontWeight: "bold",
            }}
          >
            Vision:
          </span>{" "}
          To create a world where no one faces uncertainty alone. Riley’s Alert®
          empowers individuals to stay connected in times of crisis, ensuring
          that loved ones are instantly informed and able to respond. By
          providing real-time location sharing, instant alerts, and recorded
          evidence, we bring peace of mind to both those in distress and their
          families. Whether during personal emergencies, natural disasters, or
          unforeseen tragedies, Riley’s Alert® serves as a lifeline—helping
          families stay aware, connected, and never left wondering.
        </p>
        <a
          className="Button-Regss whatisrileyBtn"
          style={{}}
          href="#download-app-section"
        >
          Download App
        </a>
      </div>
    </div>
  );
}

export default WhatIsRileys;
