import React, { useEffect } from "react";
import DownloadAppSection from "../../components/downloadAppSection/DownloadAppSection";
import RegisterAsBusinessBanner from "../../components/RegisterAsBusinessBanner/RegisterAsBusinessBanner";
import PrivacyPolicyAndTerms from "../../data/PrivacyPolicyAndTerms";
import "./a2pSmsService.css";

function A2pSmsService(props) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, [props]);
  return (
    <div className="smservice-whole-container">
      <div className="smservice-container">
        {PrivacyPolicyAndTerms.a2p.map((element) => {
          return (
            <div
              className="smservice-section-container"
              style={{
                flexDirection:
                  element.number && element.number % 2 == 0
                    ? "row"
                    : "row-reverse",
              }}
            >
              <img
                src={element.image}
                className="smservice-section-img"
                style={{
                  width: element.number ? "45%" : "52%",
                }}
              />
              <div
                className="smservice-heading-container"
                style={{
                  width: element.number ? "45%" : "43%",
                  justifyContent: element.button
                    ? "space-evenly"
                    : "flex-start",
                }}
              >
                {element.number && (
                  <h1 className="smservice-heading-number">
                    {element.number}.
                  </h1>
                )}
                {element.mainHeading && (
                  <h1 className="smservice-heading-main">
                    {element.mainHeading}.
                  </h1>
                )}
                {element.heading && (
                  <h1 className="smservice-heading-normal">
                    {element.heading}.
                  </h1>
                )}
                {element.description && (
                  <h1 className="smservice-heading-desc">
                    {element.description}.
                  </h1>
                )}
                {element.button && (
                  <button
                    className="Button-Regs smservice-button"
                    style={!element.mainHeading ? {
                      alignSelf: "flex-start",
                      marginTop: 4
                    }:{alignSelf: 'flex-start'}}
                  >
                    {element.button}
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <RegisterAsBusinessBanner />
      <DownloadAppSection />
    </div>
  );
}

export default A2pSmsService;
