import { toast } from "react-toastify";
import { simplePostRequest } from "../../utils/Api";
import { MyContext } from "../../components/contextProvider/MyContextProvider";
import { useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

export const useSignup = () => {
  const TAG = "SignupForm";
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const phoneRegex =
    /^((\+92|0)3\d{2}-?\d{7})|((\+44|0)(7\d{9}|[123]\d{8,9}))|((\+1|1)?\d{10})$/;
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
  const zipCodeRegex = /^\d{5}$/;
  const urlRegex =
    /^(https?:\/\/)?([\w\d\.-]+)\.([a-z]{2,6})(\/[\w\d\.-]*)*\/?$/;

  const [pointOfContact, setPointOfContact] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const params = useSearchParams();
  const [password, setPasswprd] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address, setAddress] = useState("");
  const [service, setService] = useState("");
  const [subService, setSubService] = useState("");
  const [socialSlug, setSocialSlug] = useState("");
  const [website, setWebsite] = useState("");
  const [providerId, setProviderId] = useState("");
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    pointOfContact: false,
    fullName: false,
    email: false,
    phone: false,
    password: false,
    confirmPassword: false,
    zipCode: false,
    address: false,
    service: false,
    subService: false,
    website: false,
    policyAccepted: false,
  });
  useEffect(() => {
    const slug = params[0].get("slug");
    if (slug && slug == "social") {
      setSocialSlug("social");
      setEmail(params[0].get("s_email"));
      setFullName(params[0].get("s_name"));
      setProviderId(params[0].get("s_providerId"));
    }
  }, []);
  const { user, setUser } = useContext(MyContext);
  async function onRegisterPress() {
    try {
      setLoading(true);
      if (validateForm()) {
        let body = {
          pointOfContact: pointOfContact,
          password: providerId ? null : password,
          confirm_password: providerId ? null : confirmPassword,
          name: fullName,
          email: email,
          phone: phone,
          service: service,
          subService: subService,
          zip_code: zipCode,
          status: "free",
          address: address,
          web_address: website,
          providerId: providerId,
        };
        const result = await simplePostRequest("business-profile", body);
        console.log(result.data);
        if (!result.error) {
          localStorage.setItem("user", JSON.stringify(result.data));

          setUser(result.data);
        }
      } else {
        toast.error("Please fill required fields");
        if (errors.policyAccepted) {
          toast.error("Accepting Privacy Policy and Terms of Use is required ");
        }
      }
    } catch (error) {
      console.log(TAG, "OnRegisterPress", error);
      toast.error(error?.message || "Something Went Wrong");
    } finally {
      setLoading(false);
    }
  }
  const validateForm = () => {
    const newErrors = { ...errors };

    // Validate each field and set the error state
    newErrors.pointOfContact = !pointOfContact;
    newErrors.fullName = !fullName;
    newErrors.email = !emailRegex.test(email);
    newErrors.phone = !phoneRegex.test(phone);
    if (!socialSlug) {
      newErrors.password = password.length < 8;
      newErrors.confirmPassword = password !== confirmPassword;
    }
    newErrors.zipCode = !zipCodeRegex.test(zipCode);
    newErrors.address = !address;
    newErrors.service = !service;
    newErrors.subService = !subService;
    newErrors.policyAccepted = !policyAccepted;
    setErrors(newErrors);
    console.log(newErrors);
    // Return false if any field is invalid
    return !Object.values(newErrors).includes(true);
  };
  const inputs = {
    _1st: [
      {
        id: 1,
        heading: "Point of Contact",
        type: "text",
        value: pointOfContact,
        onChange: (text) => {
          setPointOfContact(text);
          setErrors({
            ...errors,
            pointOfContact: false,
          });
        },
        error: errors.pointOfContact,
      },
      {
        id: 2,
        heading: "Full Name",
        type: "text",
        value: fullName,
        onChange: (text) => {
          setFullName(text);
          setErrors({
            ...errors,
            fullName: false,
          });
        },
        error: errors.fullName,
      },
    ],
    _2nd: [
      {
        id: 1,
        heading: "Email",
        type: "email",
        disabled: socialSlug && email,
        value: email,
        onChange: (text) => {
          setEmail(text);
          setErrors({
            ...errors,
            email: false,
          });
        },
        error: errors.email,
      },
      {
        id: 2,
        heading: "Phone",
        type: "tel",
        value: phone,
        disabled: false,
        onChange: (text) => {
          setPhone(text);
          setErrors({
            ...errors,
            phone: false,
          });
        },
        error: errors.phone,
      },
    ],
    _3rd: [
      {
        id: 1,
        heading: "Password",
        type: "password",
        value: password,
        onChange: (text) => {
          setPasswprd(text);
          setErrors({
            ...errors,
            password: false,
          });
        },
        error: errors.password,
      },
      {
        id: 2,
        heading: "Confirm Password",
        type: "password",
        value: confirmPassword,
        onChange: (text) => {
          setConfirmPassword(text);
          setErrors({
            ...errors,
            confirmPassword: false,
          });
        },
        error: errors.confirmPassword,
      },
    ],
    _4th: [
      {
        id: 1,
        heading: "Zip Code",
        type: "number",
        value: zipCode,
        onChange: (text) => {
          setZipCode(text);
          setErrors({
            ...errors,
            zipCode: false,
          });
        },
        error: errors.zipCode,
        style: { width: "30%" },
      },
      {
        id: 2,
        heading: "Address",
        type: "text",
        value: address,
        onChange: (text) => {
          setAddress(text);
          setErrors({
            ...errors,
            address: false,
          });
        },
        error: errors.address,
        style: {},
      },
    ],
    _5th: [
      {
        id: 1,
        heading: "Service",
        type: "text",
        value: service,
        error: errors.service,
        onChange: (text) => {
          setService(text);
          setErrors({
            ...errors,
            service: false,
          });
        },
      },
      {
        id: 2,
        heading: "Sub-Service",
        type: "text",
        value: subService,
        error: errors.subService,
        onChange: (text) => {
          setSubService(text);
          setErrors({
            ...errors,
            subService: false,
          });
        },
      },
    ],
    _6th: [
      {
        id: 1,
        heading: "Enter Website Address",
        type: "url",
        value: website,
        onChange: (text) => {
          setWebsite(text);
          setErrors({
            ...errors,
            website: false,
          });
        },
      },
    ],
  };

  return {
    socialSlug,
    policyAccepted,
    onRegisterPress,
    setPolicyAccepted,
    loading,
    inputs,
  };
};
