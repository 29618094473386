import React from "react";
import "./contactus.css";

import Header from "./components/Header";
import Cards from "./components/Cards";
import Form from "./components/Form";
import DownloadAppSection from "../../components/downloadAppSection/DownloadAppSection";
import RegisterAsBusinessBanner from "../../components/RegisterAsBusinessBanner/RegisterAsBusinessBanner";
import HomeSecEight from "../home/home-hero/home-sec-eight";

const Page = () => {
  return (
    <>
      <div className="page-container">
        <Header />
        <div className="contactus-main">
          <img
            className="image-contactus"
            src="https://images.unsplash.com/photo-1576054806269-855d0927bfa8?q=80&w=2045&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          />
          <Cards />
          <Form />
        </div>
      </div>
      <HomeSecEight />
      <RegisterAsBusinessBanner />
      <DownloadAppSection />
    </>
  );
};

export default Page;
