import React from "react";
import "./home.css"; // Import the CSS file
import { useNavigate } from "react-router";
const HeroThree = "./hero-three.png";

const HomeSecThree = () => {
  const navigate = useNavigate();
  return (
    <div className="home-sec-three">
      <div className="home-sec-three-containers">
        <div>
          <img
            src={HeroThree}
            alt="HomeSecThree"
            className="home-sec-three-image"
          />
        </div>

        <div className="home-sec-three-content">
          {/* <span className="home-container-sec-logo">
            <svg
              viewBox="0 0 70 70"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M52.5 59.7917H49.5833V59.0625C49.5833 55.8542 46.9583 53.2292 43.75 53.2292H37.1875V46.55C36.4583 46.6375 35.7292 46.6667 35 46.6667C34.2708 46.6667 33.5417 46.6375 32.8125 46.55V53.2292H26.25C23.0417 53.2292 20.4167 55.8542 20.4167 59.0625V59.7917H17.5C16.3042 59.7917 15.3125 60.7834 15.3125 61.9792C15.3125 63.175 16.3042 64.1667 17.5 64.1667H52.5C53.6958 64.1667 54.6875 63.175 54.6875 61.9792C54.6875 60.7834 53.6958 59.7917 52.5 59.7917Z"
                fill="url(#paint0_linear_260_4680)"
              />
              <path
                opacity="0.4"
                d="M16.1001 33.95C14.1751 33.2209 12.4834 32.025 11.1418 30.6834C8.42925 27.6792 6.65009 24.0917 6.65009 19.8917C6.65009 15.6917 9.94592 12.3959 14.1459 12.3959H15.7793C15.0209 13.9417 14.5834 15.6625 14.5834 17.5V26.25C14.5834 28.9917 15.1084 31.5875 16.1001 33.95Z"
                fill="url(#paint1_linear_260_4680)"
              />
              <path
                opacity="0.4"
                d="M63.35 19.8917C63.35 24.0917 61.5708 27.6792 58.8583 30.6834C57.5166 32.025 55.825 33.2209 53.9 33.95C54.8916 31.5875 55.4166 28.9917 55.4166 26.25V17.5C55.4166 15.6625 54.9791 13.9417 54.2208 12.3959H55.8541C60.0541 12.3959 63.35 15.6917 63.35 19.8917Z"
                fill="url(#paint2_linear_260_4680)"
              />
              <path
                d="M43.75 5.83337H26.25C19.8041 5.83337 14.5833 11.0542 14.5833 17.5V26.25C14.5833 37.5375 23.7125 46.6667 35 46.6667C46.2875 46.6667 55.4166 37.5375 55.4166 26.25V17.5C55.4166 11.0542 50.1958 5.83337 43.75 5.83337ZM43.2833 24.6459L41.475 26.8625C41.1833 27.1834 40.9791 27.825 41.0083 28.2625L41.1833 31.1209C41.3 32.8709 40.0458 33.775 38.4125 33.1334L35.7583 32.0834C35.35 31.9375 34.65 31.9375 34.2416 32.0834L31.5875 33.1334C29.9541 33.775 28.7 32.8709 28.8166 31.1209L28.9916 28.2625C29.0208 27.825 28.8166 27.1834 28.525 26.8625L26.7166 24.6459C25.5791 23.3042 26.075 21.8167 27.7666 21.3792L30.5375 20.6792C30.975 20.5625 31.5 20.1542 31.7333 19.775L33.2791 17.3834C34.2416 15.8959 35.7583 15.8959 36.7208 17.3834L38.2666 19.775C38.5 20.1542 39.025 20.5625 39.4625 20.6792L42.2333 21.3792C43.925 21.8167 44.4208 23.3042 43.2833 24.6459Z"
                fill="url(#paint3_linear_260_4680)"
              />
              <path
                opacity="0.4"
                d="M43.2833 24.6458L41.475 26.8625C41.1833 27.1833 40.9791 27.825 41.0083 28.2625L41.1833 31.1208C41.3 32.8708 40.0458 33.775 38.4125 33.1333L35.7583 32.0833C35.35 31.9375 34.65 31.9375 34.2416 32.0833L31.5875 33.1333C29.9541 33.775 28.7 32.8708 28.8166 31.1208L28.9916 28.2625C29.0208 27.825 28.8166 27.1833 28.525 26.8625L26.7166 24.6458C25.5791 23.3042 26.075 21.8167 27.7666 21.3792L30.5375 20.6792C30.975 20.5625 31.5 20.1542 31.7333 19.775L33.2791 17.3833C34.2416 15.8958 35.7583 15.8958 36.7208 17.3833L38.2666 19.775C38.5 20.1542 39.025 20.5625 39.4625 20.6792L42.2333 21.3792C43.925 21.8167 44.4208 23.3042 43.2833 24.6458Z"
                fill="url(#paint4_linear_260_4680)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_260_4680"
                  x1="15.3125"
                  y1="46.55"
                  x2="55.1977"
                  y2="62.924"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F81D1E" />
                  <stop offset="1" stop-color="#AA0506" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_260_4680"
                  x1="6.65009"
                  y1="12.3959"
                  x2="17.7637"
                  y2="13.2908"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F81D1E" />
                  <stop offset="1" stop-color="#AA0506" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_260_4680"
                  x1="53.9"
                  y1="12.3959"
                  x2="65.0136"
                  y2="13.2908"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F81D1E" />
                  <stop offset="1" stop-color="#AA0506" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_260_4680"
                  x1="14.5833"
                  y1="5.83337"
                  x2="61.3393"
                  y2="14.4212"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F81D1E" />
                  <stop offset="1" stop-color="#AA0506" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear_260_4680"
                  x1="26.1026"
                  y1="16.2677"
                  x2="46.4211"
                  y2="20.1576"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#F81D1E" />
                  <stop offset="1" stop-color="#AA0506" />
                </linearGradient>
              </defs>
            </svg>
          </span> */}
          <h2 className="home-sec-three-title">
            Business with Riley’s Alert
            <span className="home-sec-three-subtitle">®</span>
          </h2>
          <p className="home-sec-three-paragraph home-sec-two-para">
            The <b>Riley’s Alert® Business Directory</b> connects businesses and
            service providers with a community that prioritizes{" "}
            <b>trust, reliability, and safety.</b> Whether you're a contractor,
            professional, or business owner, our platform links you with
            individuals actively searching for your services.
          </p>

          <div
            className="home-sec-three-button-container"
            // style={}
          >
            <button
              onClick={() => navigate("/signup")}
              className="Button-Regs"
              style={{ marginTop: 0, fontSize: 12 }}
            >
              Register as Business
            </button>

            <button
              style={{ border: "none", background: "none", color: "black" }}
            >
              Learn more
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSecThree;
