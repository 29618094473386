const TAG = "API.js";
export const BASE_URL = "https://api.rileysalert.com/api/v1/";

const getRequest = async (endPoint, token = false) => {
  try {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (token) {
      headers.authorization = `${token}`;
    }
    console.log(TAG, "getRequest() :", "endpoint :", BASE_URL + endPoint);
    const response = await fetch(BASE_URL + endPoint, {
      method: "GET",
      headers,
    });

    if (!response.ok) {
      throw new Error(
        response.statusText || (await response.text()) || "Request failed"
      );
    }

    return response.json();
  } catch (error) {
    console.log(TAG, "GET Request Error:", error);
    throw error;
  }
};

export const simplePostRequest = async (
  endPoint,
  data,
  token = false,
  method = "POST",
  noHeaders = false
) => {
  try {
    const headers = {
      Accept: "application/json",
      "Content-Type":
        data instanceof FormData ? "multipart/form-data" : "application/json",
    };

    if (token) {
      headers.authorization = token;
    }
    console.log(
      TAG,
      "simplePostRequest() :",
      "endpoint :",
      BASE_URL + endPoint
    );
    console.log(TAG, "postRequest() :", "data :", data);
    const response = await fetch(BASE_URL + endPoint, {
      method,
      headers: noHeaders ? {} : headers,
      body: data instanceof FormData ? data : JSON.stringify(data),
    });

    if (!response.ok && response.status != 500 && response.status != 400) {
      // print(
      //   `POST Request Error: ${response.status} ${response.statusText}`,
      //   await response.json()
      // );
      throw new Error(
        (await response.json())?.msg || response.statusText || "Request failed"
      );
    } else if (response.status == 400 || response.status == 500) {
      return response.json();
    }

    return response.json();
  } catch (error) {
    console.log(TAG, "POST Request Error:", error);
    throw error;
  }
};

export const deleteRequest = async (endPoint, data, token = false) => {
  try {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    if (token) {
      headers.authorization = `${token}`;
    }

    const response = await fetch(BASE_URL + endPoint, {
      method: "DELETE",
      headers,
      body: data instanceof FormData ? data : JSON.stringify(data),
    });

    if (!response.ok) {
      const responseJSON = await response.json();
      console.log(TAG, "DELETE Request Error:", responseJSON.message);

      throw new Error(
        responseJSON.message || response.statusText || "Request failed"
      );
    }

    return response.json();
  } catch (error) {
    console.log(TAG, "DELETE Request Error:", error);
    throw error;
  }
};

function print(TAG, object, props = {}) {
  console.log(TAG, JSON.stringify(object, null, 2), { ...props });
}
export { getRequest, print };
