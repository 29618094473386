export default {
  policy: [
    "Effective Date: November 16, 2023. Riley's Alert ('we', 'us', or 'our') respects your privacy and is committed to protecting your personal information. This Privacy Policy explains what information we collect, how we use it, and your choices regarding your information.",

    "1. Information We Collect: We collect the following information from you: Account Information - When you create an account, we collect your name, email address, phone number, and password. Demographic Information - You may choose to provide us with additional information such as your address, date of birth, and gender. Location Information - We collect your GPS location when you activate Riley's Alert and search for businesses in the directory. You can turn off location sharing at any time in your device settings. Usage Information - We collect information about how you use the App, such as the features you use, the frequency of your use, and the content you record. Device Information - We collect information about your device, such as its type, operating system, and IP address. Payment Information - If you subscribe to the paid plan or add additional loved ones, we collect your payment information through Stripe.",

    "2. How We Use Your Information: We use your information to: Provide and improve the App, including sending push notifications and text messages in case of Riley's Alert activation. Manage your account and provide customer support. Send you marketing communications (you can opt out of these at any time). Analyze App usage and improve our services. Comply with legal requirements and enforce our Terms of Service.",

    "3. Sharing Your Information: We may share your information with the following third-party partners: Service providers - We may share your information with service providers who help us operate the App, such as cloud storage providers and payment processors. Loved ones - If you activate Riley's Alert, we will share your GPS location with the loved ones you designate. Law enforcement - We may share your information with law enforcement if required by law or if we believe it is necessary to protect the safety of others.",

    "4. Your Choices: You have the following choices regarding your information: You can access and update your account information in the App settings. You can opt out of marketing communications by emailing us at info@rileysalert.com. You can control location sharing in your device settings. You can request deletion of your account by emailing us at info@rileysalert.com.",

    "5. Data Security: We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or method of electronic storage is 100% secure. We will however use the latest technology to help us protect your information.",

    "6. Children's Privacy: The App is not intended for children under the age of 13. We do not knowingly collect or solicit personal information from children under the age of 13. If you are a parent or guardian and you believe your child has provided us with personal information, please contact us at info@rileysalert.com.",

    "7. International Transfers: Your information may be transferred to and processed in countries other than your own. These countries may have different data privacy laws than your own country.",

    "8. Changes to this Privacy Policy: We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the App. Your continued use of the App after the changes are posted constitutes your acceptance of the new Privacy Policy.",

    "9. Contact Us: If you have any questions about this Privacy Policy, please contact us at info@rileysalert.com.",
  ],
  a2p: [
    {
      mainHeading: "A2P Sms Service",
      heading: "",
      description:
        "Suggested Terms and Conditions template that aims to align with industry requirements for A2P SMS campaigns",
      button: "Explore More",
      number: null,
      image: `a2p0.png`,
    },
    {
      mainHeading: "",
      heading: "Terms and Conditions for Riley’s Alert A2P SMS Service",
      description: `Overview These Terms and Conditions govern your use of the Riley's Alert SMS messaging service (the "Service") provided by Riley's Alert. By opting-in to receive messages from this Service, you agree to be bound by these Terms.`,
      button: null,
      number: 1,
      image: `a2p1.png`,
    },
    {
      mainHeading: "",
      heading: "User Consent and Opt-Out",
      description:
        "You must provide express consent to receive SMS messages from this Service by completing the opt-in process. 2.2 You can revoke your consent and opt-out of receiving messages at any time by following the opt-out instructions in each message or contacting us.",
      button: "Explore More",
      number: 2,
      image: `a2p2.png`,
    },
    {
      mainHeading: "",
      heading: "Terms and Conditions for Riley’s Alert A2P SMS Service",
      description: `Overview These Terms and Conditions govern your use of the Riley's Alert SMS messaging service (the "Service") provided by Riley's Alert. By opting-in to receive messages from this Service, you agree to be bound by these Terms.`,
      button: null,
      number: 3,
      image: `a2p3.png`,
    },
    {
      mainHeading: "",
      heading: "User Data",
      description: `Message frequency will vary based on your activity but will not exceed 20 messages per day unless urgent notifications are required.\nWe employ reasonable data security and privacy safeguards but cannot guarantee 100% protection from unauthorized access or use.`,
      button: null,
      number: 4,
      image: `a2p4.png`,
    },
    {
      mainHeading: "",
      heading: "Compliance with Law’s",
      description: `We aim to fully comply with the Telephone Consumer Protection Act (TCPA), CTIA Messaging Principles, wireless carrier policies, and other applicable laws and regulations governing the Service.`,
      button: null,
      number: 5,
      image: `a2p5.png`,
    },
    {
      mainHeading: "",
      heading: "Service Limitations and Modifications",
      description: `We do not warrant or guarantee successful delivery of every message due to factors beyond our control.\nWe do not warrant or guarantee successful delivery of every message due to factors beyond our control.`,
      button: null,
      number: 6,
      image: `a2p6.png`,
    },
    {
      mainHeading: "",
      heading: "Limitation of Liability",
      description: `To the maximum extent permitted by law, we are not liable for any direct, indirect, incidental or consequential damages arising from your use of the Service.`,
      button: null,
      number: 7,
      image: `a2p7.png`,
    },
    {
      mainHeading: "",
      heading: "General Provisions",
      description: `Termination: You may terminate your use of the Service at any time per section 2nd\nGoverning Law: These Terms shall be governed by the laws of Georgia. 8.3 Dispute Resolution: Any disputes shall be submitted to Georgia arbitration/state courts in Cobb county`,
      button: null,
      number: 8,
      image: `a2p8.png`,
    },
  ],
  terms: [
    "Welcome to Riley's Alert! These Terms of Service ('Terms') govern your use of the Riley's Alert mobile application ('App'). By accessing or using the App, you agree to be bound by these Terms. If you disagree with any part of these Terms, you may not use the App.",
    "Services Offered Riley's Alert is a mobile application that allows users to: Record video and audio in case of emergencies using voice activation ('Riley's Alert'). Store recorded videos and audio on Riley's Alert servers. Download recorded videos and audio. Designate loved ones to receive push notifications and text messages with GPS location in case of Riley's Alert activation. Access a directory of businesses categorized by location and type. Read blog posts and participate in a Discord forum.",
    "Account and Subscription You can use the App on a subscription based model at 8.99 per month. We also have a 15 day free trial which will require user to add their credit card information and if they wish to no longer use the app they must cancel subscription before the 15 day trial period ends. You can sign up for an account using Google Play or Apple Store. You must be at least 18 years old to use the App.",
    "Fees and Payment. The paid subscription and additional loved ones feature require payment through Stripe. All payments are non-refundable. You can cancel your subscription at any time.",
    "Content and User Conduct You are responsible for all content you create or upload to the App. You must not use the App for any illegal or harmful purpose. This includes, but is not limited to, posting content that is: Defamatory or abusive Hateful or discriminatory Violent or threatening Sexually explicit Infringing on the intellectual property rights of others Riley's Alert reserves the right to remove any content that violates these Terms or is otherwise deemed inappropriate.",
    "Data Privacy Riley's Alert collects and uses your personal data to provide and improve the App. You can find more information about our data privacy practices in our Privacy Policy.",
    "Location Services The App uses location services to provide features such as sending GPS location to loved ones and searching for businesses near you. You can opt out of location sharing at any time in your device settings. If the user opts out of allowing Riley's Alert App to use their location, audio/video, SMS, Riley's Alert is not liable for any circumstances as a result.",
    "SMS Messaging Services As part of the Riley's Alert service, we may send SMS text messages to the emergency contacts you designate within the App. These messages may include your GPS location and other critical information in the event you activate the emergency alert feature.",
    "By using the Riley's Alert App and providing the mobile numbers of your designated emergency contacts, you represent and warrant that you have obtained the explicit consent of those contacts to receive SMS alerts and location information from Riley's Alert on their behalf during potential emergency situations.",
    "You agree that the SMS messages sent via the Riley's Alert service are for the sole purpose of sharing time-sensitive safety information and GPS coordinates to facilitate emergency response and assistance. The messaging service is not to be used for any commercial, promotional, or marketing purposes.",
    "We aim to fully comply with the Telephone Consumer Protection Act (TCPA), CTIA Messaging Principles, wireless carrier policies, and all other applicable laws and regulations governing the use of SMS messaging services.",
    "While we strive to deliver SMS alerts reliably, we cannot guarantee successful delivery of every message due to factors beyond our control. We are not liable for any damages arising from delayed, undelivered, or incomplete SMS messages.",
    "We reserve the right to modify or terminate the SMS messaging component of the Riley's Alert service at any time without prior notice.",
    "Video Recording and Incoming Calls If an incoming call is received while video recording is active through the Riley's Alert app, and the call is answered, the video recording will be terminated at that point. If the incoming call is not answered, video recording will continue uninterrupted. Riley's Alert is not liable for any video recording disruptions caused by incoming calls.",
    "While Riley's Alert aims to provide a personal safety app to aid users in emergencies, it is not intended to replace local emergency infrastructure or services. Users should still contact appropriate emergency responders (e.g., police, fire department, ambulance) in situations requiring immediate assistance. Riley's Alert should be used as a supplementary tool for personal safety and should not be relied upon as a substitute for official emergency services.",
    "Third-Party Services The App may integrate with third-party services such as Google Maps and Stripe. These services have their own terms of service and privacy policies.",
    "Intellectual Property Riley's Alert owns all intellectual property rights in the App. You may not copy, modify, or distribute the App without our permission.",
    "Disclaimer of Warranties The App is provided 'as is' and without any warranties, express or implied. Riley's Alert does not warrant that the App will be uninterrupted, error-free, or secure.",
    "Limitation of Liability Riley's Alert is not liable for any damages arising out of your use of the App. This includes, but is not limited to, direct, indirect, incidental, consequential, and punitive damages.",
    "Termination Riley's Alert may terminate your account or access to the App at any time, for any reason, without notice.",
    "Governing Law and Dispute Resolution These Terms are governed by and construed in accordance with the laws of the State of Georgia, without regard to conflict of laws provisions. Any dispute arising out of or relating to these Terms will be resolved by arbitration.",
    "Changes to Terms Riley's Alert reserves the right to change these Terms at any time. We will notify you of any changes by posting the new Terms on the App. Your continued use of the App after the changes are posted constitutes your acceptance of the new Terms.",
    "Contact Us If you have any questions about these Terms, please contact us at info@rileysalert.com.",
  ],
};
