import { NavLink } from "react-router-dom";
import CustomTextInput from "../../components/CustomTextInput/CustomTextInput";
import { Spinner } from "react-activity";
import "react-activity/dist/library.css";
import { useSignup } from "./useSignup";
import mastercard from "../../assets/masterCard.svg";
import visa from "../../assets/visaCard.svg";
import apple from "../../assets/applePay.svg";
import unknown from "../../assets/unknown.svg";
import anyday from "../../assets/anyday.svg";
import klarna from "../../assets/klarna.svg";
import gPay from "../../assets/gPay.svg";
import policyAccept from "../../assets/policyAccepted.svg";

export default function SignupForm() {
  const {
    socialSlug,
    policyAccepted,
    onRegisterPress,
    setPolicyAccepted,
    loading,
    inputs,
  } = useSignup();
  return (
    <div className="su-main-container">
      <div className="su-sub">
        <div className="su-sub-left">
          <p className="su-main-heading">Business Sign up</p>
          <p className="su-sub-heading">
            Already have an account?{" "}
            <NavLink
              to={"/signin"}
              style={{ textDecoration: "none", color: "#F81D1E" }}
            >
              Login to account
            </NavLink>
          </p>

          <div className="su-sub-left-row-container">
            {inputs._1st.map((input) => (
              <CustomTextInput
                heading={input.heading}
                type={input.type}
                value={input.value}
                onChange={input.onChange}
                error={input.error}
                key={input.id}
              />
            ))}
          </div>
          <div className="su-sub-left-row-container">
            {inputs._2nd.map((input) => (
              <CustomTextInput
                heading={input.heading}
                type={input.type}
                disabled={input.disabled}
                value={input.value}
                onChange={input.onChange}
                error={input.error}
                key={input.id}
              />
            ))}
          </div>
          {!socialSlug && (
            <div className="su-sub-left-row-container">
              {inputs._3rd.map((input) => (
                <CustomTextInput
                  heading={input.heading}
                  type={input.type}
                  value={input.value}
                  onChange={input.onChange}
                  error={input.error}
                  key={input.id}
                />
              ))}
            </div>
          )}
          <div className="su-sub-left-row-container">
            {inputs._4th.map((input) => (
              <CustomTextInput
                heading={input.heading}
                type={input.type}
                style={input.style}
                value={input.value}
                onChange={input.onChange}
                error={input.error}
                key={input.id}
              />
            ))}
          </div>
          <div className="su-sub-left-row-container">
            {inputs._5th.map((input) => (
              <CustomTextInput
                heading={input.heading}
                type={input.type}
                value={input.value}
                onChange={input.onChange}
                error={input.error}
                key={input.id}
              />
            ))}
          </div>
          <div className="su-sub-left-row-container">
            {inputs._6th.map((input) => (
              <CustomTextInput
                heading={input.heading}
                type={input.type}
                value={input.value}
                onChange={input.onChange}
                key={input.id}
              />
            ))}
          </div>
          <div className="su-sub-left-payment-options-container">
            <div className="su-sub-left-payment-options-subContainer">
              <p>Neet to Pay as Business Fee:</p>
              <p className="sub-sub-left-payment-price">$49.99 / Month</p>
            </div>
            <div className="su-sub-payment-method-container">
              <img src={mastercard} alt="" />
              <img src={visa} alt="" />
              <img src={apple} alt="" />
              <img src={unknown} alt="" />
              <img src={anyday} alt="" />
              <img src={klarna} alt="" />
              <img src={gPay} alt="" />
            </div>
          </div>
          <div className="su-sub-left-policy-container">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                onClick={() => setPolicyAccepted(!policyAccepted)}
                className="su-sub-left-policy-subContainer"
              >
                {policyAccepted ? (
                  <img src={policyAccept} />
                ) : (
                  <div className="su-sub-left-policy-box"></div>
                )}
              </div>
              <p className="su-sub-left-policy-text">
                I agree to Riley’s Alert{" "}
                <NavLink className={"su-link"} to={"/privacy-policy"}>
                  Privacy Policy
                </NavLink>{" "}
                and{" "}
                <NavLink className={"su-link"} to={"/terms-and-condition"}>
                  Terms of Use
                </NavLink>
              </p>
            </div>
            <button
              disabled={loading}
              onClick={() => onRegisterPress()}
              className="Button-Reg register-btn"
            >
              {loading ? <Spinner /> : "Register"}
            </button>
          </div>
        </div>
        <div className="su-form-image">
          <img src="./signup.png" />
        </div>
      </div>
    </div>
  );
}
