import React from "react";
import { useNavigate } from "react-router";
import img from "../../../assets/person.svg";
const HeroThree = "./hero-three.png";

const JoinAsPartner = () => {
  const navigate = useNavigate();
  return (
    <div className="home-sec-three">
      <div className="home-sec-three-container">
        <div>
          <img
            src={
              "https://images.unsplash.com/photo-1713623311317-d3c43a4be4cf?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            }
            alt="HomeSecThree"
            className="home-sec-three-images"
          />
        </div>

        <div className="home-sec-three-content">
          <img src={img} alt="person image" className="home-sec-three-img" />
          <h2 className="home-sec-three-subTitless" style={{}}>
            Join Riley’s Alert® as Business Partner
            {/* <span className="home-sec-three-subtitle">®</span> */}
          </h2>
          <p className="home-sec-three-paragraph font-normal">
            Become a part of the Riley’s Alert® Business Directory and connect
            with a community that values trust, safety, and preparedness. As a
            business partner, you’ll gain visibility among individuals actively
            seeking reliable services in critical situations. Whether you're a
            contractor, professional, or business owner, this is your
            opportunity to showcase your expertise and become a trusted resource
            when it matters most.
            <br />
            <br />
            <p>🔹 Boost Your Business Exposure</p>{" "}
            <p>
              🔹 Connect with a Community that Values Safety and Accountability
            </p>{" "}
            <p>🔹 Build Trust & Strengthen Your Reputation</p> <br />
            <br />
            Don’t miss out—join Riley’s Alert® today and position your business
            as a leader in safety and reliability!
          </p>

          <div className="home-sec-three-button-container">
            <button
              onClick={() => navigate("/signup")}
              className="Button-Regss"
              style={{ marginTop: 0 }}
            >
              Register as Business
            </button>

            <button
              onClick={() => navigate("/signin")}
              // style={{ border: "none", background: "none", color: "black", fontSize: 16, fontWeight: 700,  }}
              className="Login-Btn"
            >
              Login as Business
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinAsPartner;
