import React from "react";

const Email = () => {
  return (
    <div>
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M24.0014 28.7243H9.99786C5.7968 28.7243 2.99609 26.6238 2.99609 21.7226V11.9201C2.99609 7.01886 5.7968 4.91833 9.99786 4.91833H24.0014C28.2024 4.91833 31.0031 7.01886 31.0031 11.9201V21.7226C31.0031 26.6238 28.2024 28.7243 24.0014 28.7243Z"
          fill="url(#paint0_linear_3239_4903)"
        />
        <path
          d="M16.9992 18.0396C15.8229 18.0396 14.6326 17.6755 13.7224 16.9333L9.33925 13.4324C8.89114 13.0683 8.80712 12.4101 9.17121 11.962C9.5353 11.5139 10.1935 11.4299 10.6416 11.794L15.0247 15.2949C16.0889 16.1491 17.8954 16.1491 18.9597 15.2949L23.3428 11.794C23.7909 11.4299 24.4631 11.4999 24.8132 11.962C25.1772 12.4101 25.1072 13.0823 24.6451 13.4324L20.262 16.9333C19.3658 17.6755 18.1755 18.0396 16.9992 18.0396Z"
          fill="url(#paint1_linear_3239_4903)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3239_4903"
            x1="2.99609"
            y1="4.91833"
            x2="34.6684"
            y2="11.7623"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F81D1E" />
            <stop offset="1" stop-color="#AA0506" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_3239_4903"
            x1="8.94336"
            y1="11.5618"
            x2="24.7153"
            y2="18.7626"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#F81D1E" />
            <stop offset="1" stop-color="#AA0506" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default Email;
