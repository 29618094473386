import React from "react";
import linkedIn from "../../../assets/linkedin.svg";
import insta from "../../../assets/Insta.svg";
import facebook from "../../../assets/Facebook.svg";

const Header = () => {
  return (
    <div className="header-container">
      <div>
        <p className="header-text">Need help?</p>
        <h1 className="header-title" style={{ marginTop: "0" }}>
          Contact us
        </h1>
      </div>

      <div className="icon-container" style={{ marginTop: "-20px" }}>
        <a href="https://www.youtube.com/@RileysAlertofficial" target="_blank">
          <img src={linkedIn} alt="" className="icon-container-img-icon" />
        </a>
        <a href="https://www.instagram.com/rileysalert/" target="_blank">
          <img src={insta} alt="" className="icon-container-img-icon" />
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61557742146480"
          target="_blank"
        >
          <img src={facebook} alt="" className="icon-container-img-icon" />
        </a>
        {/* <LinkedIn />
        <Insta />
        <Facebook /> */}
      </div>
    </div>
  );
};

export default Header;
